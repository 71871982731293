export var CATEGORY_LABEL_LAYOUT = 'category-label-layoutType';
export var CATEGORY_LABEL_LAYOUT_PATH = "style.numbers.".concat(CATEGORY_LABEL_LAYOUT);
export var CATEGORY_LABEL_LAYOUT_MOBILE = 'category-label-mobile-layoutType';
export var CATEGORY_LABEL_LAYOUT_MOBILE_PATH = "style.numbers.".concat(CATEGORY_LABEL_LAYOUT_MOBILE);
export var CATEGORY_LABEL_LAYOUTS = {
    text: 0,
    button: 1,
};
export var CATEGORY_LABEL_MOBILE_DESIGN_TOGGLE = {
    wixParam: 'category-label-isMobileDesignSettingsEnabled',
    appSettingsPath: 'style.booleans.category-label-isMobileDesignSettingsEnabled',
    defaultValue: false,
};
export var CATEGORY_LABEL_TEXT_DESIGN_PARAMS = {
    textFont: {
        wixParam: 'category-label-text-textFont',
        appSettingsPath: 'style.fonts.category-label-text-textFont',
        defaultSize: 14,
        minSize: 10,
        maxSize: 24,
        defaultTheme: 'font_8',
    },
    textColor: {
        wixParam: 'category-label-text-textColor',
        appSettingsPath: 'style.colors.category-label-text-textColor',
        defaultColor: 'color-8',
    },
    textColorHover: {
        wixParam: 'category-label-text-textColorHover',
        appSettingsPath: 'style.colors.category-label-text-textColorHover',
        defaultColor: 'color-8',
        defaultOpacity: 0.7,
    },
    uppercase: {
        wixParam: 'category-label-text-uppercase',
        appSettingsPath: 'style.booleans.category-label-text-uppercase',
        defaultValue: false,
    },
};
export var CATEGORY_LABEL_TEXT_MOBILE_DESIGN_PARAMS = {
    textFont: {
        wixParam: 'category-label-text-mobile-textFont',
        appSettingsPath: 'style.fonts.category-label-text-mobile-textFont',
        defaultSize: 14,
        minSize: 10,
        maxSize: 24,
        defaultTheme: 'font_8',
    },
    textColor: {
        wixParam: 'category-label-text-mobile-textColor',
        appSettingsPath: 'style.colors.category-label-text-mobile-textColor',
        defaultColor: 'color-8',
    },
    uppercase: {
        wixParam: 'category-label-text-mobile-uppercase',
        appSettingsPath: 'style.booleans.category-label-text-mobile-uppercase',
        defaultValue: false,
    },
};
export var CATEGORY_LABEL_BUTTON_DESIGN_PARAMS = {
    verticalPadding: {
        wixParam: 'category-label-button-verticalPadding',
        appSettingsPath: 'style.numbers.category-label-button-verticalPadding',
        defaultPadding: 6,
        minPadding: 0,
        maxPadding: 100,
    },
    horizontalPadding: {
        wixParam: 'category-label-button-horizontalPadding',
        appSettingsPath: 'style.numbers.category-label-button-horizontalPadding',
        defaultPadding: 12,
        minPadding: 0,
        maxPadding: 100,
    },
    textFont: {
        wixParam: 'category-label-button-textFont',
        appSettingsPath: 'style.fonts.category-label-button-textFont',
        defaultSize: 14,
        minSize: 10,
        maxSize: 24,
        defaultTheme: 'font_8',
    },
    textColor: {
        wixParam: 'category-label-button-textColor',
        appSettingsPath: 'style.colors.category-label-button-textColor',
        defaultColor: 'color-1',
    },
    uppercase: {
        wixParam: 'category-label-button-uppercase',
        appSettingsPath: 'style.booleans.category-label-button-uppercase',
        defaultValue: false,
    },
    backgroundColor: {
        wixParam: 'category-label-button-backgroundColor',
        appSettingsPath: 'style.colors.category-label-button-backgroundColor',
        defaultColor: 'color-8',
        defaultOpacity: 1,
    },
    cornerRadius: {
        wixParam: 'category-label-button-cornerRadius',
        appSettingsPath: 'style.numbers.category-label-button-cornerRadius',
        defaultRadius: 0,
        minRadius: 0,
        maxRadius: 100,
    },
    borderColor: {
        wixParam: 'category-label-button-borderColor',
        appSettingsPath: 'style.colors.category-label-button-borderColor',
        defaultColor: 'color-8',
        defaultOpacity: 1,
    },
    borderWidth: {
        wixParam: 'category-label-button-borderWidth',
        appSettingsPath: 'style.numbers.category-label-button-borderWidth',
        defaultWidth: 0,
        minWidth: 0,
        maxWidth: 10,
    },
    textColorHover: {
        wixParam: 'category-label-button-textColorHover',
        appSettingsPath: 'style.colors.category-label-button-textColorHover',
        defaultColor: 'color-1',
        defaultOpacity: 1,
    },
    backgroundColorHover: {
        wixParam: 'category-label-button-backgroundColorHover',
        appSettingsPath: 'style.colors.category-label-button-backgroundColorHover',
        defaultColor: 'color-8',
        defaultOpacity: 0.7,
    },
    borderColorHover: {
        wixParam: 'category-label-button-borderColorHover',
        appSettingsPath: 'style.colors.category-label-button-borderColorHover',
        defaultColor: 'color-8',
        defaultOpacity: 0.7,
    },
};
export var CATEGORY_LABEL_BUTTON_MOBILE_DESIGN_PARAMS = {
    verticalPadding: {
        wixParam: 'category-label-button-mobile-verticalPadding',
        appSettingsPath: 'style.numbers.category-label-button-mobile-verticalPadding',
        defaultPadding: 6,
        minPadding: 0,
        maxPadding: 100,
    },
    horizontalPadding: {
        wixParam: 'category-label-button-mobile-horizontalPadding',
        appSettingsPath: 'style.numbers.category-label-button-mobile-horizontalPadding',
        defaultPadding: 12,
        minPadding: 0,
        maxPadding: 100,
    },
    textFont: {
        wixParam: 'category-label-button-mobile-textFont',
        appSettingsPath: 'style.fonts.category-label-button-mobile-textFont',
        defaultSize: 14,
        minSize: 10,
        maxSize: 24,
        defaultTheme: 'font_8',
    },
    textColor: {
        wixParam: 'category-label-button-mobile-textColor',
        appSettingsPath: 'style.colors.category-label-button-mobile-textColor',
        defaultColor: 'color-1',
    },
    uppercase: {
        wixParam: 'category-label-button-mobile-uppercase',
        appSettingsPath: 'style.booleans.category-label-button-mobile-uppercase',
        defaultValue: false,
    },
    backgroundColor: {
        wixParam: 'category-label-button-mobile-backgroundColor',
        appSettingsPath: 'style.colors.category-label-button-mobile-backgroundColor',
        defaultColor: 'color-8',
        defaultOpacity: 1,
    },
    cornerRadius: {
        wixParam: 'category-label-button-mobile-cornerRadius',
        appSettingsPath: 'style.numbers.category-label-button-mobile-cornerRadius',
        defaultRadius: 0,
        minRadius: 0,
        maxRadius: 100,
    },
    borderColor: {
        wixParam: 'category-label-button-mobile-borderColor',
        appSettingsPath: 'style.colors.category-label-button-mobile-borderColor',
        defaultColor: 'color-8',
        defaultOpacity: 1,
    },
    borderWidth: {
        wixParam: 'category-label-button-mobile-borderWidth',
        appSettingsPath: 'style.numbers.category-label-button-mobile-borderWidth',
        defaultWidth: 0,
        minWidth: 0,
        maxWidth: 10,
    },
};
