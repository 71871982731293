import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import { PinIcon } from '../icons/pin-icon';
import PostHeaderIcon from './post-header-icon';
import styles from './post-header-icons.scss';

const PostHeaderIcons = ({ post, className, iconClassName }) =>
  post.isPinned ? (
    <div className={classNames(styles.container, className)}>
      <PostHeaderIcon
        IconComponent={PinIcon}
        className={classNames('pin-icon', iconClassName)}
      />
    </div>
  ) : null;

PostHeaderIcons.propTypes = {
  post: PropTypes.object.isRequired,
  className: PropTypes.string,
  iconClassName: PropTypes.string,
};

export default PostHeaderIcons;
