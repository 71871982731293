import { once } from 'lodash';

export const importPostActions = once(async () => {
  return (
    await import(
      /* webpackChunkName: "post-actions" */
      './post-actions'
    )
  ).default;
});
