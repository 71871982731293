export var CONTENT_ALIGNMENT = 'contentAlignment';
export var CONTENT_ALIGNMENT_MOBILE = 'contentAlignmentMobile';
export var CONTENT_ALIGNMENT_PATH = "style.numbers.".concat(CONTENT_ALIGNMENT);
export var CONTENT_ALIGNMENT_MOBILE_PATH = "style.numbers.".concat(CONTENT_ALIGNMENT_MOBILE);
export var CONTENT_ALIGNMENTS = {
    left: 0,
    center: 1,
    right: 2,
};
export var CONTENT_ALIGNMENT_PARAM = {
    wixParam: CONTENT_ALIGNMENT,
    appSettingsPath: CONTENT_ALIGNMENT_PATH,
    defaultAlignment: CONTENT_ALIGNMENTS.left,
};
export var CONTENT_ALIGNMENT_MOBILE_PARAM = {
    wixParam: CONTENT_ALIGNMENT_MOBILE,
    appSettingsPath: CONTENT_ALIGNMENT_MOBILE_PATH,
    defaultAlignment: CONTENT_ALIGNMENTS.left,
};
