import PropTypes from 'prop-types';
import React from 'react';
import { SECTION_BLOG_POST_PAGE } from '@wix/communities-blog-client-common';
import BaseLink from './base-link';

const PostLink = ({ postLink, postPath, children, className, ...rest }) => {
  return (
    <BaseLink
      {...rest}
      href={postLink}
      path={postPath}
      className={className}
      children={children}
      sectionId={SECTION_BLOG_POST_PAGE}
    />
  );
};

PostLink.propTypes = {
  postLink: PropTypes.string,
  postPath: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
};

export default PostLink;
