import { flowRight } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import {
  POST_LIST_SETTINGS_PARAMS,
  getWixDataCategoryId,
  getWixDataTagId,
  isExperimentEnabled,
} from '@wix/communities-blog-client-common';
import { EXPERIMENT_POST_LIST_FILTER_WARNINGS } from '@wix/communities-blog-experiments';

import { getQueryLocale } from '../../../common/selectors/locale-selectors';
import { getIsEditorSegment } from '../../../common/store/basic-params/basic-params-selectors';
import { getAppSettingsValue } from '../../../common/selectors/app-settings-base-selectors';
import withTranslate from '../../../common/hoc/with-translate';
import EmptyStates from '../../../common/components/empty-states';

const NoPosts = ({ t, isMultilingual, showFilterWarning, ...props }) => (
  <EmptyStates
    title={
      showFilterWarning
        ? t('no-posts.filtered.title')
        : isMultilingual
        ? t('no-posts-feed.no-published-posts-multilingual')
        : t('no-posts-feed.on-the-way')
    }
    content={
      showFilterWarning
        ? t('no-posts.filtered.subtitle')
        : t('no-posts-feed.stay-tuned')
    }
    {...props}
  />
);

NoPosts.propTypes = {
  t: PropTypes.func,
  isMultilingual: PropTypes.bool,
  showFilterWarning: PropTypes.bool,
};

const mapStateToProps = ({ state }) => {
  const isPostListFilterWarningsEnabled = isExperimentEnabled(
    state,
    EXPERIMENT_POST_LIST_FILTER_WARNINGS,
  );
  const isEditorSegment = getIsEditorSegment(state);
  const isFiltered = Boolean(
    getWixDataCategoryId(state) ||
      getWixDataTagId(state) ||
      getAppSettingsValue({
        state,
        key: POST_LIST_SETTINGS_PARAMS.isFeatured.appSettingsPath,
      }),
  );
  return {
    isMultilingual: Boolean(getQueryLocale(state)),
    showFilterWarning:
      isPostListFilterWarningsEnabled && isEditorSegment && isFiltered,
  };
};

export default flowRight(connect(mapStateToProps), withTranslate)(NoPosts);
