import { trimStart } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import {
  SECTION_BLOG_PAGE,
  ROUTE_PREFIX_CATEGORIES,
} from '@wix/communities-blog-client-common';
import BaseLink from './base-link';

const CategoryLink = ({
  categoryLink,
  categoryPath,
  children,
  className,
  ...rest
}) => {
  return (
    <BaseLink
      {...rest}
      href={categoryLink}
      path={`${ROUTE_PREFIX_CATEGORIES}/${trimStart(categoryPath, '/')}`}
      className={className}
      children={children}
      sectionId={SECTION_BLOG_PAGE}
    />
  );
};

CategoryLink.propTypes = {
  categoryLink: PropTypes.string,
  categoryPath: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
};

export default CategoryLink;
