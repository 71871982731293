import { CONTENT_ALIGNMENTS } from '@wix/communities-blog-client-common';

export const getContentAlignmentStyles = (contentAlignment, isRTL) => {
  switch (contentAlignment) {
    case CONTENT_ALIGNMENTS.center:
      return 'center';
    case CONTENT_ALIGNMENTS.right:
      return isRTL ? 'start' : 'end';
    case CONTENT_ALIGNMENTS.left:
    default:
      return isRTL ? 'end' : 'start';
  }
};
