import memoizeOne from 'memoize-one';

import {
  LAYOUT_CARD_SMALL,
  LAYOUT_CARD_MEDIUM,
  LAYOUT_CARD_LARGE,
  LAYOUT_CARD_PROFILE,
  LAYOUT_FULL_POST,
  LAYOUT_MOBILE,
  LAYOUT_SIDE_BY_SIDE,
  LAYOUT_GRID,
  LAYOUT_TEXT_ON_IMAGE_SMALL,
  LAYOUT_TEXT_ON_IMAGE_MEDIUM,
  LAYOUT_ONE_COLUMN_MOBILE,
  LAYOUT_SIDE_BY_SIDE_MOBILE,
  isLayoutNameTextOnImage,
  LAYOUT_TEXT_ON_IMAGE_MOBILE,
  LAYOUT_PG_TEXT_ON_IMAGE_SMALL,
  LAYOUT_PG_TEXT_ON_IMAGE_MEDIUM,
  LAYOUT_PG_TEXT_ON_IMAGE_LARGE,
  LAYOUT_PG_CARD_MEDIUM,
  LAYOUT_PG_SIDE_BY_SIDE,
  LAYOUT_PG_SIDE_BY_SIDE_RIGHT,
  LAYOUT_PG_GRID,
  LAYOUT_PG_GRID_INTERMEDIATE,
  LAYOUT_PG_GRID_LARGE,
  LAYOUT_PG_ONE_COLUMN,
  LAYOUT_SLIDER,
  LAYOUT_LIST,
  LAYOUT_LIST_MEDIUM,
  LAYOUT_LIST_LARGE,
  POST_LIST_COMPONENT_MASONRY,
  POST_LIST_COMPONENT_SIMPLE,
  POST_LIST_COMPONENT_MOBILE,
  ITEM_COMPONENT_LIST_ITEM,
  ITEM_COMPONENT_POST,
  ITEM_COMPONENT_MOBILE_SIDE_BY_SIDE,
  ITEM_COMPONENT_MOBILE_TOI,
  SECTION_POST_LIST,
} from '@wix/communities-blog-client-common';

const LAYOUT_CONFIG = {
  [LAYOUT_CARD_SMALL]: {
    listComponentName: POST_LIST_COMPONENT_MASONRY,
    itemComponentName: ITEM_COMPONENT_LIST_ITEM,
    itemConfig: {
      displayFooterIcons: true,
      lineCount: {
        withCover: {
          title: 3,
          description: 3,
        },
        withoutCover: {
          title: 3,
          description: 3,
        },
      },
    },
  },
  [LAYOUT_CARD_MEDIUM]: {
    listComponentName: POST_LIST_COMPONENT_MASONRY,
    itemComponentName: ITEM_COMPONENT_LIST_ITEM,
    itemConfig: {
      displayFooterIcons: false,
      lineCount: {
        withCover: {
          title: 3,
          description: 3,
        },
        withoutCover: {
          title: 3,
          description: 3,
        },
      },
    },
  },
  [LAYOUT_CARD_LARGE]: {
    listComponentName: POST_LIST_COMPONENT_SIMPLE,
    itemComponentName: ITEM_COMPONENT_LIST_ITEM,
    itemConfig: {
      displayFooterIcons: false,
      lineCount: {
        withCover: {
          title: 2,
          description: 2,
        },
        withoutCover: {
          title: 2,
          description: 2,
        },
      },
    },
  },
  [LAYOUT_CARD_PROFILE]: {
    listComponentName: POST_LIST_COMPONENT_SIMPLE,
    itemComponentName: ITEM_COMPONENT_LIST_ITEM,
    itemConfig: {
      displayFooterIcons: false,
      lineCount: {
        withCover: {
          title: 2,
          description: 2,
        },
        withoutCover: {
          title: 2,
          description: 2,
        },
      },
    },
  },
  [LAYOUT_FULL_POST]: {
    listComponentName: POST_LIST_COMPONENT_SIMPLE,
    itemComponentName: ITEM_COMPONENT_POST,
    itemConfig: {
      displayFooterIcons: false,
    },
  },
  [LAYOUT_MOBILE]: {
    listComponentName: POST_LIST_COMPONENT_MOBILE,
    itemComponentName: ITEM_COMPONENT_LIST_ITEM,
    itemConfig: {
      displayFooterIcons: true,
      lineCount: {
        withCover: {
          title: 0,
          description: 3,
        },
        withoutCover: {
          title: 0,
          description: 3,
        },
      },
    },
  },
  [LAYOUT_SIDE_BY_SIDE]: {
    listComponentName: POST_LIST_COMPONENT_SIMPLE,
    itemComponentName: ITEM_COMPONENT_LIST_ITEM,
    itemConfig: {
      displayFooterIcons: false,
      lineCount: {
        withCover: {
          title: 3,
          description: 2,
        },
        withoutCover: {
          title: 2,
          description: 2,
        },
      },
    },
  },
  [LAYOUT_GRID]: {
    listComponentName: POST_LIST_COMPONENT_SIMPLE,
    itemComponentName: ITEM_COMPONENT_LIST_ITEM,
    itemConfig: {
      displayFooterIcons: true,
      lineCount: {
        withCover: {
          title: 3,
          description: 0,
        },
        withoutCover: {
          title: 3,
          description: 4,
        },
      },
    },
  },
  [LAYOUT_TEXT_ON_IMAGE_SMALL]: {
    listComponentName: POST_LIST_COMPONENT_SIMPLE,
    itemComponentName: ITEM_COMPONENT_LIST_ITEM,
    itemConfig: {
      displayFooterIcons: true,
      lineCount: {
        withCover: {
          title: 2,
          description: 0,
        },
        withoutCover: {
          title: 2,
          description: 0,
        },
      },
    },
  },
  [LAYOUT_TEXT_ON_IMAGE_MEDIUM]: {
    listComponentName: POST_LIST_COMPONENT_SIMPLE,
    itemComponentName: ITEM_COMPONENT_LIST_ITEM,
    itemConfig: {
      displayFooterIcons: true,
      lineCount: {
        withCover: {
          title: 2,
          description: 0,
        },
        withoutCover: {
          title: 2,
          description: 0,
        },
      },
    },
  },
  [LAYOUT_ONE_COLUMN_MOBILE]: {
    listComponentName: POST_LIST_COMPONENT_MOBILE,
    itemComponentName: ITEM_COMPONENT_LIST_ITEM,
    itemConfig: {
      displayFooterIcons: true,
      lineCount: {
        withCover: {
          title: 0,
          description: 3,
        },
        withoutCover: {
          title: 0,
          description: 3,
        },
      },
    },
  },
  [LAYOUT_SIDE_BY_SIDE_MOBILE]: {
    listComponentName: POST_LIST_COMPONENT_MOBILE,
    itemComponentName: ITEM_COMPONENT_MOBILE_SIDE_BY_SIDE,
    itemConfig: {
      displayFooterIcons: true,
      lineCount: {
        withCover: {
          title: 0,
          description: 3,
        },
        withoutCover: {
          title: 0,
          description: 3,
        },
      },
    },
  },
  [LAYOUT_TEXT_ON_IMAGE_MOBILE]: {
    listComponentName: POST_LIST_COMPONENT_MOBILE,
    itemComponentName: ITEM_COMPONENT_MOBILE_TOI,
    itemConfig: {
      displayFooterIcons: true,
      lineCount: {
        withCover: {
          title: 2,
          description: 0,
        },
        withoutCover: {
          title: 2,
          description: 0,
        },
      },
    },
  },
  [LAYOUT_PG_TEXT_ON_IMAGE_SMALL]: {
    listComponentName: POST_LIST_COMPONENT_SIMPLE,
    itemComponentName: ITEM_COMPONENT_LIST_ITEM,
    itemConfig: {
      displayFooterIcons: true,
      lineCount: {
        title: 3,
        description: 0,
      },
    },
  },
  [LAYOUT_PG_TEXT_ON_IMAGE_MEDIUM]: {
    listComponentName: POST_LIST_COMPONENT_SIMPLE,
    itemComponentName: ITEM_COMPONENT_LIST_ITEM,
    itemConfig: {
      displayFooterIcons: true,
      lineCount: {
        title: 3,
        description: 0,
      },
    },
  },
  [LAYOUT_PG_TEXT_ON_IMAGE_LARGE]: {
    listComponentName: POST_LIST_COMPONENT_SIMPLE,
    itemComponentName: ITEM_COMPONENT_LIST_ITEM,
    itemConfig: {
      displayFooterIcons: true,
      lineCount: {
        title: 3,
        description: 0,
      },
    },
  },
  [LAYOUT_PG_CARD_MEDIUM]: {
    listComponentName: POST_LIST_COMPONENT_MASONRY,
    itemComponentName: ITEM_COMPONENT_LIST_ITEM,
    itemConfig: {
      displayFooterIcons: false,
      lineCount: {
        title: 3,
        description: 3,
      },
    },
  },
  [LAYOUT_PG_SIDE_BY_SIDE]: {
    listComponentName: POST_LIST_COMPONENT_SIMPLE,
    itemComponentName: ITEM_COMPONENT_LIST_ITEM,
    itemConfig: {
      displayFooterIcons: false,
      lineCount: {
        title: 3,
        description: 3,
      },
    },
  },
  [LAYOUT_PG_SIDE_BY_SIDE_RIGHT]: {
    listComponentName: POST_LIST_COMPONENT_SIMPLE,
    itemComponentName: ITEM_COMPONENT_LIST_ITEM,
    itemConfig: {
      displayFooterIcons: false,
      lineCount: {
        title: 3,
        description: 3,
      },
    },
  },
  [LAYOUT_PG_GRID]: {
    listComponentName: POST_LIST_COMPONENT_SIMPLE,
    itemComponentName: ITEM_COMPONENT_LIST_ITEM,
    itemConfig: {
      displayFooterIcons: true,
      lineCount: {
        title: 3,
        description: 4,
      },
    },
  },
  [LAYOUT_PG_GRID_INTERMEDIATE]: {
    listComponentName: POST_LIST_COMPONENT_SIMPLE,
    itemComponentName: ITEM_COMPONENT_LIST_ITEM,
    itemConfig: {
      displayFooterIcons: true,
      lineCount: {
        title: 3,
        description: 4,
      },
    },
  },
  [LAYOUT_PG_GRID_LARGE]: {
    listComponentName: POST_LIST_COMPONENT_SIMPLE,
    itemComponentName: ITEM_COMPONENT_LIST_ITEM,
    itemConfig: {
      displayFooterIcons: true,
      lineCount: {
        title: 3,
        description: 4,
      },
    },
  },
  [LAYOUT_PG_ONE_COLUMN]: {
    listComponentName: POST_LIST_COMPONENT_SIMPLE,
    itemComponentName: ITEM_COMPONENT_LIST_ITEM,
    itemConfig: {
      displayFooterIcons: false,
      lineCount: {
        title: 3,
        description: 3,
      },
    },
  },
  [LAYOUT_SLIDER]: {
    itemConfig: {
      hideFooter: true,
      hideHeader: true,
      hideDescription: true,
      lineCount: {
        title: 3,
        description: 3,
      },
      displayFooterIcons: true,
    },
  },
  [LAYOUT_LIST]: {
    itemConfig: {
      displayFooterIcons: true,
      lineCount: {
        title: 3,
        description: 3,
      },
    },
  },
  [LAYOUT_LIST_MEDIUM]: {
    itemConfig: {
      displayFooterIcons: true,
      lineCount: {
        title: 3,
        description: 3,
      },
    },
  },
  [LAYOUT_LIST_LARGE]: {
    itemConfig: {
      displayFooterIcons: true,
      lineCount: {
        title: 3,
        description: 3,
      },
    },
  },
};

export const getLayoutConfig = (layoutType) => LAYOUT_CONFIG[layoutType];
export const getLineCounts = ({ lineCount }, isWithCover) =>
  isWithCover ? lineCount.withCover : lineCount.withoutCover;

const mobileSettings = [
  'title-color',
  'TOI-title-color',
  'description-color',
  'TOI-description-color',
  'overlay-background-color',
  'border-color',
  'background-color',
  'TOI-background-color',
  'post-container',
  'hover-container',
  'link-hashtag-hover-fill',
  'link-hashtag-hover-color',
  'pagination-active',
  'description-fill',
  'TOI-description-fill',
  'description-background-color',
  'TOI-description-background-color',
];
const useMobileSettings = (mobile, section, className) => {
  if (
    mobile &&
    section === SECTION_POST_LIST &&
    mobileSettings.includes(className)
  ) {
    if (className.startsWith('TOI')) {
      return className.replace('TOI', 'TOI-mobile');
    }
    return `mobile-${className}`;
  }
  return className;
};

export const createFeedClassNameGenerator = memoizeOne(
  (section, mobile) =>
    (...classes) =>
      classes.map(
        (className) =>
          `blog-post-${section}-${useMobileSettings(
            mobile,
            section,
            className,
          )}`,
      ),
);

export const getFeedColorClassName = (layoutName, name) =>
  `${isLayoutNameTextOnImage(layoutName) ? 'TOI-' : ''}${name}`;
