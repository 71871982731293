import { mapValues } from 'lodash';
import { connect } from '../components/runtime-context';
import { isExperimentEnabled } from '@wix/communities-blog-client-common';

const mapRuntimeToProps = (config) => (state) =>
  mapValues(config, (path) => isExperimentEnabled(state, path));

export default function withExperiment(config) {
  return (WrappedComponent) =>
    connect(mapRuntimeToProps(config))(WrappedComponent);
}
