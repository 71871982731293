import { formatDateICU } from '@wix/communities-blog-client-common';
import { createRtfFormatter } from '@wix/yoshi-flow-editor';
import { flowRight } from 'lodash';
import { connect } from '../components/runtime-context';
import {
  getLanguage,
  getTimezone,
} from '../store/basic-params/basic-params-selectors';
import withTranslate from './with-translate';

export default function withRelativeTimeFormatting(WrappedComponent) {
  const mapRuntimeToProps = (state) => {
    const language = getLanguage(state);
    const timezone = getTimezone(state);
    const rtfFormatter = createRtfFormatter(language);
    const formatRelativeTime = (time) =>
      formatDateICU({
        date: time,
        lng: language,
        rtfFormatter,
        timezone,
      });

    return {
      formatRelativeTime,
    };
  };

  return flowRight(withTranslate, connect(mapRuntimeToProps))(WrappedComponent);
}
