import React from 'react';
import memoizeOne from 'memoize-one';
import { isExperimentEnabled } from '@wix/communities-blog-client-common';
import { EXPERIMENT_PROD_OOI_EDITOR } from '@wix/communities-blog-experiments';
import { connect } from '../../components/runtime-context';

export const createPageLink = memoizeOne(
  (createPageUrl, handleNavigation = () => {}) => {
    const PageLink = ({
      children,
      page,
      isDisabled,
      isActive,
      navigateInPreview,
      isExperimentProdOOIEditorEnabled,
      ...props
    }) => {
      if (isActive || isDisabled) {
        return <div {...props}>{children}</div>;
      }
      const pageUrl = createPageUrl(page);
      return (
        <a
          href={pageUrl}
          onClick={(event) =>
            handleNavigation({
              event,
              page,
              pageUrl,
              navigateInPreview,
              isExperimentProdOOIEditorEnabled,
            })
          }
          {...props}
        >
          {children}
        </a>
      );
    };

    const mapRuntimeToProps = (state, _ownProps, actions) => ({
      navigateInPreview: actions.navigateInPreview,
      isExperimentProdOOIEditorEnabled: isExperimentEnabled(
        state,
        EXPERIMENT_PROD_OOI_EDITOR,
      ),
    });

    return connect(mapRuntimeToProps)(PageLink);
  },
);
