export var getTimezoneDate = function (date, timezone) {
    if (!date) {
        return undefined;
    }
    var d = new Date(date);
    if (timezone) {
        var dateTimezoneOffset = getTimezoneOffset(timezone);
        var localTimezoneOffset = d.getTimezoneOffset();
        var difference = dateTimezoneOffset - localTimezoneOffset;
        return new Date(new Date(d.getTime() - difference * 60 * 1000).toISOString());
    }
    return d;
};
var getTimezoneOffset = function (tz) {
    var d = new Date();
    var a = d
        .toLocaleString('ja', { timeZone: tz })
        .split(/[/\s:]/)
        .map(Number);
    a[1]--;
    var t1 = Date.UTC.apply(Date, a);
    var t2 = new Date(d).setMilliseconds(0);
    return (t2 - t1) / 60 / 1000;
};
