import { flowRight } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';

import CounterCompact from '../../../common/components/counter-compact';
import { CommentIcon } from '../../../common/components/icons/comment-icon';
import withLayoutColorClasses from '../../../common/hoc/with-layout-color-classes';
import withTranslate from '../../../common/hoc/with-translate';

const CommentCountCompact = ({ t, iconColorClassName, ...props }) => (
  <CounterCompact {...props} dataHook="comment-count-compact" ariaHidden={true}>
    <CommentIcon
      aria-label={t('comment-count.label', {
        count: props.count,
        numberFormatted: props.countFormatted,
      })}
      className={classNames(iconColorClassName, 'blog-link-hover-fill')}
    />
  </CounterCompact>
);

CommentCountCompact.propTypes = {
  count: PropTypes.number,
  iconColorClassName: PropTypes.string,
  showZero: PropTypes.bool,
  t: PropTypes.func,
};

CommentCountCompact.defaultProps = {
  showZero: false,
};

export default flowRight(
  withTranslate,
  withLayoutColorClasses,
)(CommentCountCompact);
