import { connect } from '../components/runtime-context';
import {
  getVideoHost,
  getImageHost,
} from '@wix/communities-blog-client-common';

const withMediaHosts = (WrappedComponent) => {
  const mapRuntimeToProps = (state) => ({
    videoHost: getVideoHost(state),
    imageHost: getImageHost(state),
  });

  return connect(mapRuntimeToProps)(WrappedComponent);
};

export default withMediaHosts;
