import { __assign } from "tslib";
import { isObject, isString } from 'lodash';
import { getImageUrl } from './image-utils';
import { isAbsoluteUrl } from './url';
export default function resolveUserImage(image, _a) {
    var _b = _a === void 0 ? {} : _a, type = _b.type, _c = _b.width, width = _c === void 0 ? 100 : _c, _d = _b.height, height = _d === void 0 ? 100 : _d, quality = _b.quality, format = _b.format, blur = _b.blur, imageHost = _b.imageHost;
    if (isObject(image)) {
        if (!image.file_name) {
            image = __assign(__assign({}, image), { file_name: image.id });
        }
        return getImageUrl({
            imageHost: imageHost,
            image: image,
            maxWidth: width,
            maxHeight: height,
            quality: quality,
            type: type,
            format: format,
            blur: blur,
        });
    }
    if (isString(image) && !isAbsoluteUrl(image)) {
        return getImageUrl({
            imageHost: imageHost,
            image: { file_name: image },
            maxWidth: width,
            maxHeight: height,
            quality: quality,
            type: type,
            format: format,
            blur: blur,
        });
    }
    return image;
}
