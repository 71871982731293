import { isSSR } from './is-ssr';
import { getImageUrl as _getImageUrl } from '@wix/communities-blog-universal/dist/src/utils/media/image';
import { resizeThumbnail as _resizeThumbnail } from '@wix/communities-blog-universal/dist/src/utils/media/thumbnail';
export { getImageFilename } from '@wix/communities-blog-universal/dist/src/utils/media/image';
var WIDTHS_MOBILE = [320, 375, 425, 768];
export var screenWidth = isSSR() ? 980 : window.screen.width;
export var screenPixelRatio = isSSR() ? 1 : window.devicePixelRatio;
export var getFormat = function (isSSR) {
    return !isSSR && window.__IS_WEBP_SUPPORTED__ ? 'webp' : 'png';
};
var snapToClosestScreenWidth = function (w) {
    return WIDTHS_MOBILE.reduce(function (closest, v) {
        var d1 = v - w;
        var d2 = closest - w;
        return Math.abs(d1) < Math.abs(d2)
            ? d1 >= 0 || d2 < 0
                ? v
                : closest
            : closest;
    });
};
var getProportionalHeight = function (originalWidth, originalHeight, w) {
    return w / (originalWidth / originalHeight);
};
var getProportionalWidth = function (originalWidth, originalHeight, h) {
    return h * (originalWidth / originalHeight);
};
// eslint-disable-next-line max-params
var adjustSizeByPixelRatio = function (originalWidth, originalHeight, w, h, pixelRatio) {
    var nW = Math.ceil(w * pixelRatio);
    var nH = Math.ceil(h * pixelRatio);
    var isLargerThanOrig = nW > originalWidth || nH > originalHeight;
    return [
        isLargerThanOrig ? originalWidth : nW,
        isLargerThanOrig ? originalHeight : nH,
    ];
};
/**
 * Returns filled [width, height] of an image that is best for current device based on given params.
 * @param oW - original image width
 * @param oH - original image height
 * @param w - new image width (the size that image will be shown)
 * @param h - new image height (the size that image will be shown)
 * @param isMobile
 * @param sW - screenWidth, injected for testing, on live taken from window
 * @param pR - pixelRatio, injected for testing, on live taken from window
 * @returns [width, height]
 */
// eslint-disable-next-line max-params
export var getFillSize = function (oW, oH, w, h, isMobile, sW, pR) {
    if (sW === void 0) { sW = screenWidth; }
    if (pR === void 0) { pR = screenPixelRatio; }
    if (isMobile && (!w || w > sW)) {
        w = snapToClosestScreenWidth(sW);
    }
    if (w >= oW || h >= oH || !(w || h)) {
        return [oW, oH];
    }
    else if (w && h) {
        var propW = getProportionalWidth(oW, oH, h);
        var propH = getProportionalHeight(oW, oH, w);
        var scaleByW = propH >= h;
        return adjustSizeByPixelRatio(oW, oH, scaleByW ? w : propW, scaleByW ? propH : h, pR);
    }
    else if (w) {
        return adjustSizeByPixelRatio(oW, oH, w, getProportionalHeight(oW, oH, w), pR);
    }
    else if (h) {
        return adjustSizeByPixelRatio(oW, oH, getProportionalWidth(oW, oH, h), h, pR);
    }
};
export var getImageUrl = function (_a) {
    var image = _a.image, imageHost = _a.imageHost, _b = _a.maxWidth, maxWidth = _b === void 0 ? 1000 : _b, _c = _a.maxHeight, maxHeight = _c === void 0 ? 1000 : _c, quality = _a.quality, type = _a.type, format = _a.format, blur = _a.blur, _d = _a.encode, encode = _d === void 0 ? false : _d;
    return _getImageUrl(imageHost)(image, {
        maxWidth: maxWidth,
        maxHeight: maxHeight,
        quality: quality,
        type: type,
        format: format,
        blur: blur,
        encode: encode,
    });
};
export var resizeThumbnail = function (_a) {
    var pathname = _a.pathname, width = _a.width, height = _a.height, imageHost = _a.imageHost;
    return _resizeThumbnail(imageHost)(pathname, width, height);
};
