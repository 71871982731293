import PropTypes from 'prop-types';
import React from 'react';
import { connect } from '../runtime-context';
import classNames from 'classnames';

import { ArrowLeftIcon } from '../icons/arrow-left-icon';
import { ArrowRightIcon } from '../icons/arrow-right-icon';
import { getIsRTL } from '../../store/basic-params/basic-params-selectors';
import Pagination from '../pagination';
import styles from './pagination-regular.scss';

const PaginationRegular = ({
  activeClass,
  showPosition,
  pageClass,
  positionClass,
  arrowFillClass,
  isRTL,
  ...props
}) => {
  const getArrow = (isRTL, isRightSide = true) => {
    let Icon;

    if (isRTL) {
      Icon = isRightSide ? ArrowLeftIcon : ArrowRightIcon;
    } else {
      Icon = isRightSide ? ArrowRightIcon : ArrowLeftIcon;
    }

    return <Icon className={arrowFillClass} />;
  };

  return (
    <Pagination
      className={classNames(
        styles.container,
        showPosition && styles.withPosition,
      )}
      arrowClass={styles.arrow}
      innerClass={classNames(styles.page, pageClass)}
      activeClass={classNames(styles.active, 'blog-button-color', activeClass)}
      disabledClass={styles.disabled}
      positionClass={classNames(styles.position, positionClass)}
      showPosition={showPosition}
      arrowLeft={getArrow(isRTL, false)}
      arrowRight={getArrow(isRTL)}
      {...props}
    />
  );
};

PaginationRegular.propTypes = {
  showPosition: PropTypes.bool,
  arrowFillClass: PropTypes.string,
  pageClass: PropTypes.string,
  positionClass: PropTypes.string,
  isRTL: PropTypes.bool,
};

PaginationRegular.defaultProps = {
  arrowFillClass: 'blog-icon-fill',
};

const mapRuntimeToProps = (state) => ({
  isRTL: getIsRTL(state),
});

export default connect(mapRuntimeToProps)(PaginationRegular);
