import { merge } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';

import styles from './tooltip.scss';

const getStyle = (style, distanceFromElement) =>
  merge(
    style,
    style.top && { top: `${Number(style.top) - distanceFromElement}px` },
  );

const Tooltip = ({
  children,
  style = {},
  className,
  triangle = {},
  distanceFromElement,
}) => (
  <div
    data-hook="tooltip-container"
    className={classNames(className, styles.container)}
    style={getStyle(style, distanceFromElement)}
  >
    <span
      className={classNames(
        styles.triangleBorder,
        triangle.borderColorClass || styles.defaultColor,
      )}
      style={{ borderBottomColor: triangle.borderColor }}
    />
    {children}
    <span
      className={classNames(
        styles.triangleBackground,
        triangle.borderBottomColorClass || styles.defaultColor,
      )}
      style={{ borderBottomColor: triangle.backgroundColor }}
    />
  </div>
);

Tooltip.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  style: PropTypes.object,
  triangle: PropTypes.shape({
    borderColor: PropTypes.string.isRequired,
    backgroundColor: PropTypes.string.isRequired,
  }),
  distanceFromElement: PropTypes.number,
};

Tooltip.defaultProps = {
  distanceFromElement: 2,
};

export default Tooltip;
