import { get } from 'lodash';

export const getInstanceValues = (state) => state.instanceValues;
export const getInstanceValue = (state, name, fallback) =>
  get(getInstanceValues(state), name, fallback);
export const getIsDemoMode = (state) => getInstanceValue(state, 'demoMode');
export const getBiToken = (state) => getInstanceValue(state, 'biToken');
export const getAid = (state) => getInstanceValue(state, 'aid');
export const getPermissions = (state) => getInstanceValue(state, 'permissions');
export const getMetaSiteId = (state) => getInstanceValue(state, 'metaSiteId');
export const getSiteIsTemplate = (state) =>
  getInstanceValue(state, 'siteIsTemplate');
export const getInstanceId = (state) => getInstanceValue(state, 'instanceId');
export const getHasUserRole = (state) =>
  getInstanceValue(state, 'hasUserRole', false);
