import React from 'react';
import ResponsiveContext from './responsive-context';

export default function withResponsiveContext(Component) {
  class WithResponsiveContext extends React.Component {
    static displayName = `WithResponsiveContext`;
    static contextType = ResponsiveContext;

    render() {
      return <Component {...this.props} {...this.context} />;
    }
  }
  return WithResponsiveContext;
}
