import React from 'react';
import PropTypes from 'prop-types';
import memoizeOne from 'memoize-one';
import { POST_EXCERPT_MAX_LENGTH } from '@wix/communities-blog-client-common';
import DotDotDot from '../dotdotdot';
import { getContentText } from '../../services/content-text';
import styles from './post-list-item-pro-gallery-excerpt.scss';

const getDescriptionStyle = memoizeOne((descriptionLineCount) => ({
  WebkitLineClamp: descriptionLineCount,
}));

const PostListItemProGalleryExcerpt = ({
  post: { content, excerpt },
  lineCount,
  descriptionLineCount,
}) => {
  const text = excerpt
    ? excerpt.slice(0, POST_EXCERPT_MAX_LENGTH)
    : getContentText(content).slice(0, POST_EXCERPT_MAX_LENGTH);

  return (
    <div className={styles.container}>
      {descriptionLineCount ? (
        <div
          className={styles.clamp}
          style={getDescriptionStyle(descriptionLineCount)}
        >
          {text}
        </div>
      ) : (
        <DotDotDot
          clamp="auto"
          maxLineCount={lineCount}
          className={styles.text}
        >
          {text}
        </DotDotDot>
      )}
    </div>
  );
};

PostListItemProGalleryExcerpt.propTypes = {
  post: PropTypes.object.isRequired,
  lineCount: PropTypes.number,
  descriptionLineCount: PropTypes.number,
};

export default PostListItemProGalleryExcerpt;
