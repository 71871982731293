import { flowRight } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import memoizeOne from 'memoize-one';

import DotDotDot from '../dotdotdot';
import withLayoutColorClasses from '../../hoc/with-layout-color-classes';
import withIsFeedDesignEnabled from '../../hoc/with-is-feed-design-enabled';
import { getFeedColorClassName } from '../../services/layout-config';
import { connect } from '../runtime-context';
import styles from './post-list-item-pro-gallery-title.scss';
import { getPostListMobileLayoutNameForTitleFontColor } from '../../selectors/mobile-settings-selectors';

const getTitleStyle = memoizeOne((titleLineCount, layoutSidesPadding) => ({
  WebkitLineClamp: titleLineCount,
  width: `calc(100% - ${layoutSidesPadding || 0})`,
}));

export const PostListItemProGalleryTitle = ({
  style,
  getPostClassName,
  layoutName,
  lineCount,
  titleLineCount,
  title,
  type,
  showCategoryLabel,
  layoutSidesPadding,
  mobileLayoutName,
}) => {
  const titleClassName = classNames(
    getPostClassName(
      getFeedColorClassName(mobileLayoutName || layoutName, 'title-color'),
      'title-font',
    ),
  );
  const className = classNames(
    styles.title,
    styles[type],
    'post-title',
    'blog-hover-container-element-color',
    titleClassName,
    showCategoryLabel && styles.withCategoryLabel,
  );

  return (
    <div className={className} style={style} data-hook="post-title">
      {titleLineCount ? (
        <p
          className={classNames(
            style.fontSize ? styles.inheritFont : titleClassName,
            styles.clamp,
          )}
          style={getTitleStyle(titleLineCount, layoutSidesPadding)}
        >
          {title}
        </p>
      ) : (
        <DotDotDot
          useExactLineHeight={true}
          clamp="auto"
          maxLineCount={lineCount}
          className={style.fontSize ? styles.inheritFont : titleClassName}
          isTitle={true}
        >
          {title}
        </DotDotDot>
      )}
    </div>
  );
};

PostListItemProGalleryTitle.propTypes = {
  title: PropTypes.string.isRequired,
  type: PropTypes.string,
  lineCount: PropTypes.number,
  layoutSidesPadding: PropTypes.number,
  titleLineCount: PropTypes.number,
  style: PropTypes.object,
  layoutName: PropTypes.string,
  getPostClassName: PropTypes.func,
  showCategoryLabel: PropTypes.bool,
  mobileLayoutName: PropTypes.string,
};

const mapRuntimeToProps = (state) => {
  return {
    mobileLayoutName: getPostListMobileLayoutNameForTitleFontColor(state),
  };
};

export default flowRight(
  withLayoutColorClasses,
  withIsFeedDesignEnabled,
  connect(mapRuntimeToProps),
)(PostListItemProGalleryTitle);
