import { __spreadArray } from "tslib";
import { POST_STATUS } from '@wix/communities-blog-universal/dist/src/constants/post';
export var ENTITY_TYPE_POSTS = 'posts';
export var ENTITY_TYPE_MANAGE_POSTS_DRAFTS = "posts-".concat(POST_STATUS.unpublished);
export var ENTITY_TYPE_MANAGE_POSTS_PUBLISHED = "posts-".concat(POST_STATUS.published);
export var ENTITY_TYPE_MANAGE_POSTS_SCHEDULED = "posts-".concat(POST_STATUS.scheduled);
export var ENTITY_TYPE_MANAGE_POSTS_TRASH = "posts-".concat(POST_STATUS.deleted);
export var ENTITY_TYPE_MANAGE_POSTS_PENDING_REVIEW = "posts-".concat(POST_STATUS.in_review);
export var ENTITY_TYPE_COMMENTS = 'comments';
export var POST_ENTITY_TYPES = [
    ENTITY_TYPE_POSTS,
    ENTITY_TYPE_MANAGE_POSTS_DRAFTS,
    ENTITY_TYPE_MANAGE_POSTS_PUBLISHED,
    ENTITY_TYPE_MANAGE_POSTS_SCHEDULED,
    ENTITY_TYPE_MANAGE_POSTS_TRASH,
    ENTITY_TYPE_MANAGE_POSTS_PENDING_REVIEW,
];
export var ALL_ENTITY_TYPES = __spreadArray([ENTITY_TYPE_COMMENTS], POST_ENTITY_TYPES, true);
