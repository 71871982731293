export const getWrappersHorizontalMargin = ({
  layoutPGSideBySide,
  layoutPGOneColumn,
  layoutMargins,
  createdWithResponsiveEditor,
}) => {
  if (layoutPGSideBySide || layoutPGOneColumn) {
    return 'auto';
  }

  return createdWithResponsiveEditor ? 0 : layoutMargins;
};

export const getContainerWidth = ({
  layoutPGSideBySide,
  layoutPGOneColumn,
  width,
  layoutMargins,
  borderWidth,
  createdWithResponsiveEditor,
  layoutPostSize,
  layoutSlider,
}) => {
  if (layoutPGSideBySide || layoutPGOneColumn) {
    return layoutPostSize;
  }

  width = width || 0;
  let result;

  if (layoutSlider) {
    result = Math.max(1, width - layoutMargins * 2 - borderWidth * 2);
  } else if (createdWithResponsiveEditor || layoutMargins < 1) {
    result = Math.max(1, width);
  } else {
    result = Math.max(1, width - layoutMargins * 2);
  }

  return isNaN(result) ? 1 : result;
};
