import { getOuterUrl } from './get-outer-url';
export var getInternalLinkRoute = function (_a) {
    var topology = _a.topology, to = _a.to, shouldNavigateToFeed = _a.shouldNavigateToFeed;
    var baseUrl = topology.baseUrl, isHomePage = topology.isHomePage, feedSectionUrl = topology.sectionUrl, postPageSectionUrl = topology.postPageSectionUrl;
    var path = to === '/' ? '' : to;
    var sectionUrl = shouldNavigateToFeed
        ? path === '' && isHomePage
            ? baseUrl
            : feedSectionUrl
        : postPageSectionUrl;
    return getOuterUrl(path, sectionUrl);
};
