import React from 'react';
import { connect } from '../components/runtime-context';
import { getIsMemberAreaInstalled } from '../store/communities-context/communities-context-selectors';
import { getIsNavigationAllowed } from '../store/ma-navigation/ma-navigation-selectors';

const forMembersArea = (ComponentMembersArea, ComponentNoMembersArea) => {
  const Component = ({ isMemberAreaActive, ...props }) => {
    const Component = isMemberAreaActive
      ? ComponentMembersArea
      : ComponentNoMembersArea;
    return <Component {...props} />;
  };

  const mapRuntimeToProps = (state) => ({
    isMemberAreaActive: getIsMemberAreaInstalled(state),
    isNavigationAllowed: getIsNavigationAllowed(state),
  });

  return connect(mapRuntimeToProps)(Component);
};

export default forMembersArea;
