import PropTypes from 'prop-types';
import React from 'react';
import { connect } from '../runtime-context';
import { isSite as getIsSite } from '../../store/basic-params/basic-params-selectors';

const BaseLink = ({
  href,
  path,
  sectionId,
  onClick,
  children,
  className,
  isSite,
  navigateToSectionInPreview,
  ...rest
}) => {
  const handleClick = (event) => {
    if (!isSite) {
      event.preventDefault();
      navigateToSectionInPreview(path, sectionId);
      return;
    }

    onClick?.();
  };

  return (
    <a {...rest} href={href} onClick={handleClick} className={className}>
      {children}
    </a>
  );
};

BaseLink.propTypes = {
  href: PropTypes.string,
  path: PropTypes.string,
  sectionId: PropTypes.string,
  onClick: PropTypes.func,
  children: PropTypes.node,
  className: PropTypes.string,

  isSite: PropTypes.bool,
  navigateToSectionInPreview: PropTypes.func.isRequired,
};

const mapRuntimeToProps = (state, _ownProps, actions) => ({
  isSite: getIsSite(state),
  navigateToSectionInPreview: actions.navigateToSectionInPreview,
});

export default connect(mapRuntimeToProps)(BaseLink);
