import { SECTION_MY_POSTS } from './sections';
export var MY_POSTS_PAGE_TITLE_FONT = "page-".concat(SECTION_MY_POSTS, "-titleFont");
export var MY_POSTS_PAGE_TITLE_COLOR = "page-".concat(SECTION_MY_POSTS, "-titleColor");
export var MY_POSTS_PAGE_TAB_FONT = "page-".concat(SECTION_MY_POSTS, "-tabFont");
export var MY_POSTS_PAGE_TAB_COLOR = "page-".concat(SECTION_MY_POSTS, "-tabColor");
export var MY_POSTS_PAGE_ACTIVE_TAB_COLOR = "page-".concat(SECTION_MY_POSTS, "-activeTabColor");
export var MY_POSTS_PAGE_LINK_FONT = "page-".concat(SECTION_MY_POSTS, "-linkFont");
export var MY_POSTS_PAGE_LINK_COLOR = "page-".concat(SECTION_MY_POSTS, "-linkColor");
export var MY_POSTS_PAGE_BACKGROUND_COLOR = "page-".concat(SECTION_MY_POSTS, "-backgroundColor");
export var MY_POSTS_PAGE_BORDER_COLOR = "page-".concat(SECTION_MY_POSTS, "-borderColor");
export var MY_POSTS_PAGE_BORDER_WIDTH = "page-".concat(SECTION_MY_POSTS, "-borderWidth");
export var MY_POSTS_PAGE_BORDER_WIDTH_DEFAULT_VALUE = 0;
