import { get } from 'lodash';
import { createSelector } from 'reselect';
import { USER_PRIVACY_STATUS_PRIVATE } from '../constants/user-privacy-status';
import { BLOG_APP_ID } from '@wix/communities-universal/dist/src/constants/appsConfig';
export var getCurrentUser = function (state) { return get(state, 'auth.currentUser'); };
export var getCurrentUserSiteMemberId = createSelector([getCurrentUser], function (currentUser) { return currentUser && currentUser.siteMemberId; });
export var getCurrentUserSlug = createSelector([getCurrentUser], function (currentUser) { return currentUser && currentUser.slug; });
export var getCurrentUserCounters = createSelector([getCurrentUser], function (currentUser) { return currentUser && currentUser.counters; });
export var isOwner = createSelector([getCurrentUser], function (currentUser) {
    return Boolean(currentUser && currentUser.isOwner);
});
export var getCurrentUserRole = createSelector([getCurrentUser], function (currentUser) {
    return get(currentUser, "appStore[".concat(BLOG_APP_ID, "].permissions.role"), 'guest');
});
export var isBlocked = createSelector([getCurrentUser], function (currentUser) {
    return Boolean(currentUser && currentUser.isBlocked);
});
export var isPrivate = createSelector([getCurrentUser], function (currentUser) {
    return currentUser && currentUser.privacyStatus === USER_PRIVACY_STATUS_PRIVATE;
});
