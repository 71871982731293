import { includes } from 'lodash';
import { YEAR_SLASH_MONTH_OPTIONAL_SLASH_DAY } from '@wix/communities-blog-universal/dist/src/constants/regexp';
import { encodeURIComponentIfNeeded } from './uri';
import { resolveId } from '@wix/communities-blog-client-common';

const REGEXP_YEAR_MONTH_DAY = new RegExp(
  `^${YEAR_SLASH_MONTH_OPTIONAL_SLASH_DAY}/$`,
);

export const isOldSlug = (entity, slug) =>
  !includes([resolveId(entity), entity.slug], slug);

export const isValidAdvancedSlug = ({ year, month, day, postSlug }) => {
  if (!postSlug) {
    return false;
  }
  if (!day) {
    return REGEXP_YEAR_MONTH_DAY.test(`${year}/${month}/`);
  }
  return !isNaN(new Date(`${year}-${month}-${day}`).getTime());
};

export const resolvePostSlug = ({ year, month, day, postSlug } = {}) => {
  if (!isValidAdvancedSlug({ year, month, day, postSlug })) {
    return postSlug;
  }
  return day
    ? `${year}/${month}/${day}/${postSlug}`
    : `${year}/${month}/${postSlug}`;
};

export const resolveCategorySlug = ({ categorySlug, subcategorySlug } = {}) => {
  const slug = subcategorySlug
    ? `${categorySlug}/${subcategorySlug}`
    : categorySlug;
  return slug ? slug.toLowerCase() : slug;
};

export const encodeURISlug = (slug) =>
  slug.split('/').map(encodeURIComponentIfNeeded).join('/');
