export var icuOptions = {
    formats: {
        date: {
            monthAndYearUtc: { month: 'long', year: 'numeric' },
            date: { month: 'short', day: 'numeric' },
            fullDate: { year: 'numeric', month: 'short', day: 'numeric' },
        },
        time: {
            hoursAndMinutes: { hour: 'numeric', minute: '2-digit' },
        },
    },
};
