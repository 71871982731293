import {
  isEditor,
  isPreview,
  isSeo,
} from '../store/basic-params/basic-params-selectors';
import { GALLERY_CONSTS } from 'pro-gallery'; // eslint-disable-line import/no-extraneous-dependencies

export const getProGalleryViewMode = (state, rootWidth) => {
  if (isEditor(state)) {
    return GALLERY_CONSTS.viewMode.EDIT;
  }

  if (isPreview(state)) {
    return GALLERY_CONSTS.viewMode.PREVIEW;
  }

  if (isSeo(state)) {
    return GALLERY_CONSTS.viewMode.SEO;
  }

  if (!rootWidth) {
    return GALLERY_CONSTS.viewMode.PRERENDER;
  }

  return GALLERY_CONSTS.viewMode.SITE;
};
