import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';

import styles from './user-name.scss';

const UserName = ({ name, className }) => (
  <span
    title={name}
    className={classNames(styles.container, 'user-name', className)}
    data-hook="user-name"
  >
    {name}
  </span>
);

UserName.propTypes = {
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
};

export default UserName;
