import { flowRight } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';

import { OwnerSmallIcon } from '../icons/owner-small-icon';
import { WriterIcon } from '../icons/writer-icon';
import { EditorSmallIcon } from '../icons/editor-small-icon';
import Tooltip from '../tooltip';
import {
  ROLE_OWNER,
  ROLE_EDITOR,
  ROLE_WRITER,
} from '@wix/communities-blog-universal/dist/src/permissions-checker-constants';
import withTranslate from '../../hoc/with-translate';
import withLayoutColorClasses from '../../hoc/with-layout-color-classes';
import styles from './badge.scss';

const BADGES = {
  [ROLE_OWNER]: OwnerSmallIcon,
  [ROLE_EDITOR]: EditorSmallIcon,
  [ROLE_WRITER]: WriterIcon,
};

class Badge extends React.Component {
  state = {
    isTooltipVisible: false,
  };

  handleMouseEnter = () => {
    this.timeout = setTimeout(() => {
      this.setState({
        isTooltipVisible: true,
      });
    }, 150);
  };

  handleMouseLeave = () => {
    clearTimeout(this.timeout);
    this.setState({
      isTooltipVisible: false,
    });
  };

  componentWillUnmount() {
    clearTimeout(this.timeout);
  }

  render = () => {
    const { role, t, iconColorClassName, ariaLabel } = this.props;
    const Icon = BADGES[role];

    if (!Icon) {
      return null;
    }

    return (
      <div
        aria-label={ariaLabel}
        data-hook="badge"
        className={styles.container}
        onMouseEnter={this.handleMouseEnter}
        onMouseLeave={this.handleMouseLeave}
      >
        {this.state.isTooltipVisible && (
          <Tooltip className={styles.tooltip}>{t(`badge.${role}`)}</Tooltip>
        )}
        <Icon className={iconColorClassName} />
      </div>
    );
  };
}

Badge.propTypes = {
  role: PropTypes.string.isRequired,
  iconColorClassName: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
  ariaLabel: PropTypes.string,
};

export default flowRight(withLayoutColorClasses, withTranslate)(Badge);
